import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/_App/layout'
import Seo from '../../components/_App/seo'
import TopHeader from '../../components/Common/TopHeader'
import Navbar from '../../components/_App/Navbar'
import Why from '../../components/Products/Why'
import ProductBanner from '../../components/Products/ProductBanner'
import What from '../../components/Products/What'
import Footer from '../../components/_App/Footer'
import TalkArea from '../../components/Common/TalkArea'
import FaqArea from '../../components/FAQ/FaqArea'
import Pricing from '../../components/Products/Pricing'

import Data from '../../components/Data/Data'

const myQuery1 = graphql`
  query GetInnoRugFeatureDetails {
    feature: strapiProductFeature(productId: { eq: "innorug" }) {
      title
      helpText
      featureCard {
        icon
        shortDesc
        title
      }
    }
  }
`

const InnoRug = () => {
  const {
    feature: { helpText, title, featureCard },
  } = useStaticQuery(myQuery1)

  return (
    <Layout>
      <Seo
        title="InnoRug - Cloud ERP"
        description="An innovation driven Cloud ERP Solution that integrates Rug Manufacturing Process, in an efficient and effective business suite."
        image="https://res.cloudinary.com/innoage/image/upload/v1696906542/ozv6ulvzx3vk5o9zc1o2.png"
      />

      <TopHeader />

      <Navbar />

      <ProductBanner
        image="https://res.cloudinary.com/innoage/image/upload/v1696912977/ku9yzdogosniikektl4j.png"
        name="InnoRug - Cloud ERP"
        description="An innovation driven Cloud ERP Solution that Unlock Efficiency, Precision, and Growth in Your Rug Manufacturing Process"
      />

      <Why why={Data.whyInnorug} />

      <What helpText={helpText} title={title} featureCard={featureCard} />

      <TalkArea />

      <Pricing pricingDetails={Data.innorugPricing} />

      <FaqArea
        description="We at InnoAge always believe in Transparency with our clients & employees, please check
            list of frequently asked questions. Feel free to ask if this doesn't help."
        faq1={Data.faqir1}
        faq2={Data.faqir2}
      />

      <Footer />
    </Layout>
  )
}

export default InnoRug
