import React from 'react'
import { Grid } from '@mui/material'

import './Pricing.css'

const Pricing = props => {
  const pricingGrids = 12 / props.pricingDetails.grids.length
  console.log(pricingGrids)
  return (
    <div className="pricing-area pt-5 pb-4" id="pricing">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="p-1"
      >
        <Grid item className="section-title text-center">
          <h2>{props.pricingDetails.title}</h2>
          <span className="sp-color1">{props.pricingDetails.subtitle}</span>
          <p className="sp-color2">
            <strong>Note</strong>: Choose a plan that fits your needs and budget, with pricing
            options tailored by our Sales Executive.
          </p>
        </Grid>
        <Grid item className="m-4 pl-5 pr-5">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            {props.pricingDetails.grids.map((pricing, index) => {
              return (
                <Grid item lg={pricingGrids} md={pricingGrids} sm={8} xs={10} key={index}>
                  <div
                    className={`single-pricing-card single-pricing-card-style-two ${
                      pricing.isRecommended ? ' active' : ''
                    }`}
                  >
                    <div className="pricing-bar">
                      <span>{pricing.name}</span>
                      {/* <h2>
                            {pricing.price}
                            <sub>{pricing.priceUnit}</sub>
                          </h2> */}
                      <p>{pricing.description}</p>
                      <div className="pricing-card-shape">
                        <img
                          src="https://res.cloudinary.com/innoage/image/upload/v1683980673/pricing-footer_o7svfm.png"
                          alt="shape"
                        />
                      </div>
                    </div>
                    <div className="price-list">
                      {props.isModal ? (
                        <div
                          role="button"
                          className="default-btn mb-3 get-hover-effect"
                          onClick={() => {
                            props.setSelectedPrice('pricing', pricing.id, pricing.id)
                            props.setPricingModal(false)
                          }}
                          onKeyDown={() => {
                            props.setSelectedPrice('pricing', pricing.id, pricing.id)
                            props.setPricingModal(false)
                          }}
                          tabIndex={0}
                        >
                          Choose Plan
                        </div>
                      ) : (
                        // <Link to="/signup" className="default-btn mb-3 get-hover-effect">
                        //   Start 30 Day Trial
                        // </Link>
                        <a
                          href="https://innoage.org/contact"
                          target="_blank"
                          rel="noreferrer"
                          className="default-btn mb-3 get-hover-effect"
                        >
                          Start 30 Day Trial
                        </a>
                      )}
                      <ul>
                        {pricing.features.map((pricing, index) => {
                          return (
                            <li key={index}>
                              <i className="bx bxs-check-circle" color="green" size="1.4rem" />{' '}
                              {pricing}
                              {/* <AiOutlineCheckCircle color="green" size="1.4rem" />  */}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    {pricing.isRecommended && <span className="recommended">Recommended</span>}
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      {!props.isModal && (
        <>
          <div className="shape shape-8">
            <img
              src="https://res.cloudinary.com/innoage/image/upload/v1683980437/shape-8_spbxda.png"
              alt="shape-8"
            />
          </div>
          <div className="shape shape-10">
            <img
              src="https://res.cloudinary.com/innoage/image/upload/v1683980383/shape-10_w4ckii.png"
              alt="shape-10"
            />
          </div>
          <div className="shape shape-9">
            <img
              src="https://res.cloudinary.com/innoage/image/upload/v1683980383/shape-9_ngag9z.png"
              alt="shape-9"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Pricing
